import { call, put, takeEvery } from 'redux-saga/effects'
import cogoTost from 'cogo-toast'
import { get, put as putMethod, post } from 'helpers/api_helper'
import axios from 'axios'

import { GET_CITIES, GET_STATES, GET_ZIPCODES, GET_USERS, GET_USERS_INFO, GET_USER_CONTENTS_CITY, GET_USER_CONTENTS_STATE, GET_USER_CONTENTS_ZIPCODES, GET_USER_CONTENT, UPDATE_DETAIL, ADD_USER } from 'store/user/actionTypes'
import { setCities, setStates, setZipcodes, setUsers, setUserInfo, setUserContentsCity, setUserContentsState, setUserContentsZipcodes, setUserContent } from './actions'

let usersToken = null

function* getUserStates() {
  try {
    const { data } = yield call(get, '/admin/users/states')
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setStates(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getUserCities() {
  try {
    const { data } = yield call(get, '/admin/users/cities')
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setCities(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getUserZipcodes() {
  try {
    const { data } = yield call(get, '/admin/users/zipcodes')
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setZipcodes(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getUsers({ payload: { q } }) {
  try {
    if (usersToken) usersToken.cancel()
    usersToken = axios.CancelToken.source()
    yield put(setUsers({ isLoading: true }))
    const { data } = yield call(get, `/admin/users?${q}`, {
      cancelToken: usersToken.token
    })
    yield put(setUsers({ ...data.data, isLoading: false }))
  } catch (error) {
    if (!axios.isCancel(error)) {
      cogoTost.error(error.response.data.message)
    }
  }
}

function* getUsersInfo({ payload: { id } }) {
  try {
    yield put(setUserInfo({ isLoading: true, data: null }))
    const { data } = yield call(get, `/admin/users/${id}`, {})
    yield put(setUserInfo({ data: data.data, isLoading: false }))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

// -------
function* getUContentsCity({ payload: { id } }) {
  try {
    const { data } = yield call(get, `/admin/users/contents/cities/${id}`)
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setUserContentsCity(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getUContentsState({ payload: { id } }) {
  try {
    const { data } = yield call(get, `/admin/users/contents/states/${id}`)
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setUserContentsState(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getUContentsZipcode({ payload: { id } }) {
  try {
    const { data } = yield call(get, `/admin/users/contents/zipcodes/${id}`)
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setUserContentsZipcodes(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getUContent({ payload: { id, q } }) {
  try {
    yield put(setUserContent({ isLoading: true }))
    const { data } = yield call(get, `/admin/users/contents/${id}?${q}`, {})
    yield put(setUserContent({ ...data.data, isLoading: false }))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* updateDetails({ payload: { id, values, setSubmitting, setIsReadOnly } }) {
  try {
    const { data } = yield call(putMethod, `/admin/users/${id}`, values)
    cogoTost.success(data.message)
    setSubmitting(false)
    setIsReadOnly(true)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* addUser({ payload: { values, setSubmitting, history, setUserRecId } }) {
  try {
    const { data } = yield call(post, `/admin/users`, values)
    cogoTost.success(data.message)
    // values.role == 1 ? history.push("/customers") : history.push("/pros")
    setSubmitting(false)
    setUserRecId(data.data.recordId)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* UserSaga() {
  yield takeEvery(GET_STATES, getUserStates)
  yield takeEvery(GET_CITIES, getUserCities)
  yield takeEvery(GET_ZIPCODES, getUserZipcodes)
  yield takeEvery(GET_USERS, getUsers)
  yield takeEvery(GET_USERS_INFO, getUsersInfo)
  yield takeEvery(GET_USER_CONTENTS_CITY, getUContentsCity)
  yield takeEvery(GET_USER_CONTENTS_STATE, getUContentsState)
  yield takeEvery(GET_USER_CONTENTS_ZIPCODES, getUContentsZipcode)
  yield takeEvery(GET_USER_CONTENT, getUContent)
  yield takeEvery(UPDATE_DETAIL, updateDetails)
  yield takeEvery(ADD_USER, addUser)
}

export default UserSaga
