import { call, put, takeEvery } from 'redux-saga/effects'
import cogoTost from 'cogo-toast'
import { get, put as putMethod, post, del } from 'helpers/api_helper'
import axios from 'axios'

import {
  setHelps,
  setHelpInfo,
  getHelps as getHelpsAction,
  setInvoices,
  setInvoiceInfo,
  setAllStates,
  // setAllZipcodes,
  setAnnouncements,
  setAnnouncementInfo
} from './actions'
import {
  GET_HELPS,
  GET_HELP_INFO,
  UPDATE_HELP_STATUS,
  DELETE_TICKETS,
  ADD_TICKETS,
  GET_INVOICES,
  GET_INVOICE_INFO,
  GET_ALL_STATES,
  // GET_ALL_ZIPCODES,
  ADD_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENT_STATUS,
  DELETE_ANNOUNCEMENTS,
  GET_ANNOUNCEMENT_INFO,
  UPDATE_ANNOUNCEMENT,
  ADD_INVOICES,
  UPDATE_INVOICES,
  DELETE_INVOICES,
  DELETE_INVOICE,
  REFUND_CONFIRM,
  REFUND_INVOICE_TRANSACTION,
  RECHARGE_INVOICE
} from './actionTypes'
import moment from 'moment'

let helpToken = null

function* getHelps({ payload: { q } }) {
  try {
    if (helpToken) helpToken.cancel()
    helpToken = axios.CancelToken.source()
    yield put(setHelps({ isLoading: true }))
    const { data } = yield call(get, `/admin/helps?${q}`, {
      cancelToken: helpToken.token
    })
    yield put(setHelps({ ...data.data, isLoading: false }))
  } catch (error) {
    if (!axios.isCancel(error)) {
      cogoTost.error(error.response.data.message)
    }
  }
}

function* getHelpInfo({ payload: { id } }) {
  try {
    yield put(setHelpInfo({ isLoading: true, data: null }))
    const { data } = yield call(get, `/admin/helps/${id}`, {})
    yield put(setHelpInfo({ data: data.data, isLoading: false }))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* updateHelpStatus({ payload: { id, status, q, update = false } }) {
  try {
    const { data } = yield call(post, `/admin/helps/${id}`, { status })
    cogoTost.success(data.message)
    if (update) {
      yield put(getHelpsAction({ q }))
    }
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* deleteTickets({ payload: { ids, q, update = false, setIds, setSinglebtn, setDelBtnLoader } }) {
  try {
    setDelBtnLoader(true)
    const { data } = yield call(post, `/admin/helps/remove`, { ids })
    cogoTost.success(data.message)
    if (update) {
      yield put(getHelpsAction({ q }))
      setIds([])
      setSinglebtn(false)
      setDelBtnLoader(false)
    }
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* addTicket({ payload: { values, setSubmitting, history } }) {
  try {
    const { data } = yield call(post, `/admin/helps/create`, values)
    cogoTost.success(data.message)
    history.push('/tickets')
    setSubmitting(false)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* addInvoice({ payload: { values, history, setSubmitting } }) {
  try {
    const { data } = yield call(post, `admin/invoices`, values)
    cogoTost.success(data.message)
    history.push('/invoices')
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* deleteInvoices({
  payload: { ids, update = false, callListingApi, q, setIds, setSinglebtn, setDelBtnLoader }
}) {
  try {
    setDelBtnLoader(true)
    const { data } = yield call(post, `/admin/invoices/remove`, { ids })
    cogoTost.success(data.message)
  } catch (error) {
    cogoTost.error(error.response.data.message)
  } finally {
    if (update) {
      yield put(callListingApi({ q }))
    }
    setIds([])
    setSinglebtn(false)
    setDelBtnLoader(false)
  }
}

function* deleteInvoice({ payload: { ids, setDelBtnLoader, history } }) {
  try {
    setDelBtnLoader(true)
    const { data } = yield call(post, `/admin/invoices/remove`, { ids })
    cogoTost.success(data.message)
    history.push('/invoices')
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* updateInvoice({ payload: { values, id, setSubmitting, history } }) {
  try {
    const { data } = yield call(putMethod, `admin/invoices/${id}`, values)
    cogoTost.success(data.message)
    history.goBack()
  } catch (error) {
    cogoTost.error(error.response.data.message)
  } finally {
    setSubmitting(false)
  }
}

function* getInvoices({ payload: { q } }) {
  try {
    if (helpToken) helpToken.cancel()
    helpToken = axios.CancelToken.source()
    yield put(setInvoices({ isLoading: true }))
    const { data } = yield call(get, `/admin/invoices?${q}`, {
      cancelToken: helpToken.token
    })
    yield put(setInvoices({ ...data.data, isLoading: false }))
  } catch (error) {
    if (!axios.isCancel(error)) {
      cogoTost.error(error.response.data.message)
    }
  }
}

function* getInvoiceInfo({ payload: { id } }) {
  try {
    yield put(setInvoiceInfo({ isLoading: true, data: null }))
    const { data } = yield call(get, `/admin/invoices/${id}`, {})
    yield put(setInvoiceInfo({ data: data.data, isLoading: false }))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

// ====== Announcement ======
function* getAllStates() {
  try {
    const { data } = yield call(get, '/admin/auth/states')
    const _data = data.data.map((d) => ({
      // ...d,
      label: d.abbrivationCode,
      value: d.abbrivationCode
    }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setAllStates(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

// function* getAllZipcodes() {
//   try {
//     const { data } = yield call(get, "/admin/auth/zipcode?limit=10&offset=0")
//     const _data = data.data.records.map(d => ({
//       // ...d,
//       label: d.zipcode,
//       value: d.zipcode,
//     }))
//     _data.unshift({ label: "ALL", value: "all" })
//     yield put(setAllZipcodes(_data))
//   } catch (error) {
//     cogoTost.error(error.response.data.message)
//   }
// }

function* addAnnouncement({ payload: { newValues, setSubmitting, history } }) {
  try {
    const { data } = yield call(post, `/admin/announcements`, newValues)
    cogoTost.success(data.message)
    history.push('/announcements')
    setSubmitting(false)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* getAnnouncements({ payload: { q } }) {
  try {
    if (helpToken) helpToken.cancel()
    helpToken = axios.CancelToken.source()
    yield put(setAnnouncements({ isLoading: true }))
    const { data } = yield call(get, `/admin/announcements?${q}`, {
      cancelToken: helpToken.token
    })
    yield put(setAnnouncements({ ...data.data, isLoading: false }))
  } catch (error) {
    if (!axios.isCancel(error)) {
      cogoTost.error(error.response.data.message)
    }
  }
}

function* updateAnnouncementStatus({ payload: { id, status, update = false, callListingApi, q } }) {
  try {
    const { data } = yield call(putMethod, `/admin/announcements/update-status/${id}`, { status })
    cogoTost.success(data.message)
    if (update) {
      yield put(callListingApi({ q }))
    }
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* deleteAnnouncements({
  payload: { ids, update = false, callListingApi, q, setIds, setSinglebtn, setDelBtnLoader }
}) {
  try {
    setDelBtnLoader(true)
    const { data } = yield call(post, `/admin/announcements/remove`, { ids })
    cogoTost.success(data.message)
    if (update) {
      yield put(callListingApi({ q }))
      setIds([])
      setSinglebtn(false)
      setDelBtnLoader(false)
    }
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getAnnouncementInfo({ payload: { id } }) {
  try {
    yield put(setAnnouncementInfo({ isLoading: true, data: null }))
    const { data } = yield call(get, `/admin/announcements/${id}`, {})
    yield put(setAnnouncementInfo({ data: data.data, isLoading: false }))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* updateAnnouncement({ payload: { id, newValues, setSubmitting, setIsReadOnly, setDate } }) {
  try {
    const { data } = yield call(putMethod, `/admin/announcements/${id}`, newValues)
    cogoTost.success(data.message)
    setSubmitting(false)
    setIsReadOnly(true)
    setDate(moment().valueOf())
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* refundConfirm({ payload: { id, setRefundDopdown, setRefundLoader } }) {
  try {
    const { data } = yield call(post, `/admin/helps/refund/approve/${id}`)
    cogoTost.success(data.message)
    if (typeof setRefundDopdown === 'function') setRefundDopdown(false)
    if (typeof setRefundLoader === 'function') setRefundLoader(false)
    yield put({ type: GET_HELP_INFO, payload: { id } })
  } catch (error) {
    cogoTost.error(error.response.data.message)
    yield put({ type: GET_HELP_INFO, payload: { id } })
  }
}

function* rechargeConfirm({ payload: { id, setRechargeDropdown, setRechargeLoader } }) {
  try {
    const { data } = yield call(post, `/admin/invoices/${id}/recharge`)
    cogoTost.success(data.message)
    if (typeof setRechargeDropdown === 'function') setRechargeDropdown(false)
    if (typeof setRechargeLoader === 'function') setRechargeLoader(false)
    yield put({ type: GET_INVOICE_INFO, payload: { id } })
  } catch (error) {
    cogoTost.error(error.response.data.message)
    yield put({ type: GET_INVOICE_INFO, payload: { id } })
    if (typeof setRechargeDropdown === 'function') setRechargeDropdown(false)
    if (typeof setRechargeLoader === 'function') setRechargeLoader(false)
  }
}

function* refundInvoiceTransaction({ payload: { id, onConfirm } }) {
  try {
    const { data } = yield call(post, `/admin/invoices/refund/${id}`)
    cogoTost.success(data.message)
    if (typeof onConfirm === 'function') onConfirm()
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* HelpSaga() {
  yield takeEvery(GET_HELPS, getHelps)
  yield takeEvery(GET_HELP_INFO, getHelpInfo)
  yield takeEvery(UPDATE_HELP_STATUS, updateHelpStatus)
  yield takeEvery(DELETE_TICKETS, deleteTickets)
  yield takeEvery(ADD_TICKETS, addTicket)
  yield takeEvery(GET_INVOICES, getInvoices)
  yield takeEvery(ADD_INVOICES, addInvoice)
  yield takeEvery(DELETE_INVOICES, deleteInvoices)
  yield takeEvery(DELETE_INVOICE, deleteInvoice)
  yield takeEvery(UPDATE_INVOICES, updateInvoice)
  yield takeEvery(GET_INVOICE_INFO, getInvoiceInfo)
  yield takeEvery(GET_ALL_STATES, getAllStates)
  // yield takeEvery(GET_ALL_ZIPCODES, getAllZipcodes)
  yield takeEvery(ADD_ANNOUNCEMENT, addAnnouncement)
  yield takeEvery(GET_ANNOUNCEMENTS, getAnnouncements)
  yield takeEvery(UPDATE_ANNOUNCEMENT_STATUS, updateAnnouncementStatus)
  yield takeEvery(DELETE_ANNOUNCEMENTS, deleteAnnouncements)
  yield takeEvery(GET_ANNOUNCEMENT_INFO, getAnnouncementInfo)
  yield takeEvery(UPDATE_ANNOUNCEMENT, updateAnnouncement)
  yield takeEvery(REFUND_CONFIRM, refundConfirm)
  yield takeEvery(REFUND_INVOICE_TRANSACTION, refundInvoiceTransaction)
  yield takeEvery(RECHARGE_INVOICE, rechargeConfirm)
}

export default HelpSaga
