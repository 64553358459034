export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"

export const VERIFY_CODE = "VERIFY_CODE"
export const RESEND_OTP = "RESEND_OTP"

export const RESET_PASSWORD = "RESET_PASSWORD"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
