import {
  GET_CITIES,
  SET_CITIES,
  GET_STATES,
  SET_STATES,
  GET_ZIPCODES,
  SET_ZIPCODES,
  GET_USERS,
  SET_USERS,
  GET_USERS_INFO,
  SET_USERS_INFO,
  GET_USER_CONTENTS_CITY,
  SET_USER_CONTENTS_CITY,
  GET_USER_CONTENTS_STATE,
  SET_USER_CONTENTS_STATE,
  GET_USER_CONTENTS_ZIPCODES,
  SET_USER_CONTENTS_ZIPCODES,
  GET_USER_CONTENT,
  SET_USER_CONTENT,
  UPDATE_DETAIL,
  ADD_USER,
} from "./actionTypes"

export const getStates = () => ({ type: GET_STATES })
export const setStates = states => ({ type: SET_STATES, payload: states })

export const getCities = () => ({ type: GET_CITIES })
export const setCities = cities => ({ type: SET_CITIES, payload: cities })

export const getZipcodes = () => ({ type: GET_ZIPCODES })
export const setZipcodes = zipcodes => ({
  type: SET_ZIPCODES,
  payload: zipcodes,
})

export const getUsers = payload => ({ type: GET_USERS, payload })
export const setUsers = users => ({ type: SET_USERS, payload: users })

export const getUserInfo = payload => ({ type: GET_USERS_INFO, payload })
export const setUserInfo = user => ({ type: SET_USERS_INFO, payload: user })

export const getUserContentsCity = payload => ({
  type: GET_USER_CONTENTS_CITY,
  payload,
})
export const setUserContentsCity = cities => ({
  type: SET_USER_CONTENTS_CITY,
  payload: cities,
})

export const getUserContentsState = payload => ({
  type: GET_USER_CONTENTS_STATE,
  payload,
})
export const setUserContentsState = states => ({
  type: SET_USER_CONTENTS_STATE,
  payload: states,
})

export const getUserContentsZipcodes = payload => ({
  type: GET_USER_CONTENTS_ZIPCODES,
  payload,
})
export const setUserContentsZipcodes = zipcodes => ({
  type: SET_USER_CONTENTS_ZIPCODES,
  payload: zipcodes,
})

export const getUserContent = payload => ({ type: GET_USER_CONTENT, payload })
export const setUserContent = uContent => ({
  type: SET_USER_CONTENT,
  payload: uContent,
})

export const updateDetails = payload => ({ type: UPDATE_DETAIL, payload })

export const addUser = payload => ({ type: ADD_USER, payload })
