export const GET_HELPS = 'GET_HELPS'
export const SET_HELPS = 'SET_HELPS'

export const GET_HELP_INFO = 'GET_HELP_INFO'
export const SET_HELP_INFO = 'SET_HELP_INFO'

export const UPDATE_HELP_STATUS = 'UPDATE_HELP_STATUS'

export const DELETE_TICKETS = 'DELETE_TICKETS'

export const ADD_TICKETS = 'ADD_TICKETS'
export const REFUND_CONFIRM = 'REFUND_CONFIRM'

export const ADD_INVOICES = 'ADD_INVOICES'
export const GET_INVOICES = 'GET_INVOICES'
export const SET_INVOICES = 'SET_INVOICES'
export const UPDATE_INVOICES = 'UPDATE_INVOICES'
export const REFUND_INVOICE_TRANSACTION = 'REFUND_INVOICE_TRANSACTION'

export const DELETE_INVOICES = 'DELETE_INVOICES'
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const RECHARGE_INVOICE = 'RECHARGE_INVOICE'

export const GET_INVOICE_INFO = 'GET_INVOICE_INFO'
export const SET_INVOICE_INFO = 'SET_INVOICE_INFO'

export const GET_ALL_STATES = 'GET_ALL_STATES'
export const SET_ALL_STATES = 'SET_ALL_STATES'

// export const GET_ALL_ZIPCODES = "GET_ALL_ZIPCODES"
// export const SET_ALL_ZIPCODES = "SET_ALL_ZIPCODES"

export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT'

export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS'
export const SET_ANNOUNCEMENTS = 'SET_ANNOUNCEMENTS'

export const UPDATE_ANNOUNCEMENT_STATUS = 'UPDATE_ANNOUNCEMENT_STATUS'
export const DELETE_ANNOUNCEMENTS = 'DELETE_ANNOUNCEMENTS'

export const GET_ANNOUNCEMENT_INFO = 'GET_ANNOUNCEMENT_INFO'
export const SET_ANNOUNCEMENT_INFO = 'SET_ANNOUNCEMENT_INFO'

export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT'
