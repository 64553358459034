import { call, put, takeEvery } from 'redux-saga/effects'
import cogoTost from 'cogo-toast'
import { get, put as putMethod, post } from 'helpers/api_helper'

import { GET_DASHBOARD_INFO, GET_ACTIVE_USERS_CHART, GET_REGISTERED_USERS_CHART, GET_USERS_MAP_CHART } from './actionTypes'

import { setActiveUsers, setRegisteredUsers, setUserMap } from './actions'
import { setDashboardInfo } from './actions'
import moment from 'moment'

function* getDashboardInfo() {
  try {
    // yield put(setDashboardInfo({ isLoading: true, data: null }))
    // const { data } = yield call(get, '/admin/dashboard')
    // yield put(setDashboardInfo({ data: data.data, isLoading: false }))
    // cogoTost.success("okkk")
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getRegisteredUsers({ payload: { q } }) {
  try {
    yield put(setRegisteredUsers({ isLoading: true, data: null }))
    const { data } = yield call(get, `/admin/dashboard/registered-user?${q}`, {})

    const registerLabels = []
    const registerChartData = []
    const diffChartData = []

    // const newRegisterLabels = []
    // const newRegisterChartData = []

    for (const r of data.data.register) {
      registerLabels.push(moment.utc(r.date, 'YYYY-MM-DD').format('DD'))
      registerChartData.push(r.counts)
      diffChartData.push(r.diff)
    }

    // for (const r of data.data.newRegister) {
    //   newRegisterLabels.push(moment(r.date, "YYYY-MM-DD").format("DD"))
    //   newRegisterChartData.push(r.counts)
    // }

    yield put(
      setRegisteredUsers({
        registeredUsers: { registerLabels, registerChartData },
        newRegisterUsers: {
          newRegisterLabels: registerLabels,
          newRegisterChartData: diffChartData
        },
        isLoading: false
      })
    )
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getActiveUsers({ payload: { q } }) {
  try {
    yield put(setActiveUsers({ isLoading: true }))
    const { data } = yield call(get, `/admin/dashboard/active-users?${q}`, {})

    const acticeUsersLabels = []
    const acticeUsersChartData = []

    for (const r of data.data) {
      acticeUsersLabels.push(moment.utc(r._id, 'YYYY-MM-DD').format('DD'))
      acticeUsersChartData.push(r.count)
    }

    yield put(
      setActiveUsers({
        activeUsers: {
          acticeUsersLabels,
          acticeUsersChartData,
          isLoading: false
        }
      })
    )
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getUserMap() {
  try {
    yield put(setUserMap({ usersMap: { data: [], total: 0, loading: true } }))
    const { data } = yield call(get, `/admin/dashboard/footprint-map`, {})
    let total = 0
    for (const r of data.data) {
      total = total + r.count
    }
    const { data: footprint } = yield call(get, `/admin/dashboard/footprint`, {})
    yield put(setUserMap({ usersMap: { data: data.data, total: total, loading: false, header: footprint.data } }))
  } catch (error) {
    cogoTost.error(error.response.data.message)
    yield put(setUserMap({ usersMap: { data: [], total: 0, loading: false } }))
  }
}

function* DashboardSaga() {
  yield takeEvery(GET_DASHBOARD_INFO, getDashboardInfo)
  yield takeEvery(GET_REGISTERED_USERS_CHART, getRegisteredUsers)
  yield takeEvery(GET_ACTIVE_USERS_CHART, getActiveUsers)
  yield takeEvery(GET_USERS_MAP_CHART, getUserMap)
}

export default DashboardSaga
