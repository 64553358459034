import {
  ADD_MANAGER,
  GET_MANAGERS,
  GET_MANAGER_INFO,
  SET_MANAGER,
  SET_MANAGERS,
  SET_MANAGER_INFO,
  UPDATE_MANAGER_INFO,
  SEND_DELETE_MFA,
  DELETE_MANAGER,
} from "./actionTypes"

export const addManager = payload => ({
  type: ADD_MANAGER,
  payload,
})

export const setManagerId = payload => ({
  type: SET_MANAGER,
  payload,
})

export const getManagers = payload => ({
  type: GET_MANAGERS,
  payload,
})

export const setManagers = payload => ({
  type: SET_MANAGERS,
  payload,
})

export const getManagerInfo = payload => ({
  type: GET_MANAGER_INFO,
  payload,
})

export const setManagerInfo = payload => ({
  type: SET_MANAGER_INFO,
  payload,
})

export const updateManagerInfo = payload => ({
  type: UPDATE_MANAGER_INFO,
  payload,
})

export const sendDeleteMFA = (event, setSinglebtn2) => {
  return {
    type: SEND_DELETE_MFA,
    payload: { event, setSinglebtn2 },
  }
}

export const deleteManager = (id, mfaOtp, event, history) => ({
  type: DELETE_MANAGER,
  payload: { id, mfaOtp, event, history },
})
