import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

// Profile
const UserProfile = lazy(() => import('../pages/Authentication/user-profile'))

// Pages Calendar
const Calendar = lazy(() => import('../pages/Calendar/index'))

//Email
const EmailInbox = lazy(() => import('../pages/Email/email-inbox'))
const EmailRead = lazy(() => import('../pages/Email/email-read'))
const EmailCompose = lazy(() => import('../pages/Email/email-compose'))

const Emailtemplatealert = lazy(() => import('../pages/EmailTemplate/email-template-alert'))
const Emailtemplatebasic = lazy(() => import('../pages/EmailTemplate/email-template-basic'))
const Emailtemplatebilling = lazy(() => import('../pages/EmailTemplate/email-template-billing'))

// Authentication related pages
const Login = lazy(() => import('../pages/Authentication/Login'))
const Logout = lazy(() => import('../pages/Authentication/Logout'))
const ForgetPwd = lazy(() => import('../pages/Authentication/ForgetPassword'))
const VerifyCode = lazy(() => import('../pages/Authentication/VerifyCode'))
const ResetPassword = lazy(() => import('../pages/Authentication/ResetPassword'))
const ChangePassword = lazy(() => import('../pages/Authentication/ChangePassword'))
const VerifyMFA = lazy(() => import('../pages/Authentication/VerifyMFA'))

const Register = lazy(() => import('../pages/Authentication/Register'))

//  // Inner Authentication
const Login1 = lazy(() => import('../pages/AuthenticationInner/Login'))
const Login2 = lazy(() => import('../pages/AuthenticationInner/Login2'))
const Register1 = lazy(() => import('../pages/AuthenticationInner/Register'))
const Register2 = lazy(() => import('../pages/AuthenticationInner/Register2'))
const Recoverpw = lazy(() => import('../pages/AuthenticationInner/Recoverpw'))
const Recoverpw2 = lazy(() => import('../pages/AuthenticationInner/Recoverpw2'))
const ForgetPwd1 = lazy(() => import('../pages/AuthenticationInner/ForgetPassword'))
const LockScreen = lazy(() => import('../pages/AuthenticationInner/auth-lock-screen'))
const LockScreen2 = lazy(() => import('../pages/AuthenticationInner/auth-lock-screen-2'))
const ConfirmMail = lazy(() => import('../pages/AuthenticationInner/page-confirm-mail'))
const ConfirmMail2 = lazy(() => import('../pages/AuthenticationInner/page-confirm-mail-2'))
const EmailVerification = lazy(() => import('../pages/AuthenticationInner/auth-email-verification'))
const EmailVerification2 = lazy(() => import('../pages/AuthenticationInner/auth-email-verification-2'))
const TwostepVerification = lazy(() => import('../pages/AuthenticationInner/auth-two-step-verification'))
const TwostepVerification2 = lazy(() => import('../pages/AuthenticationInner/auth-two-step-verification-2'))

// Dashboard
const Dashboard = lazy(() => import('../pages/Dboard/index'))
// Charts
const ChartApex = lazy(() => import('../pages/Charts/Apexcharts'))
const ChartistChart = lazy(() => import('../pages/Charts/ChartistChart'))
const ChartjsChart = lazy(() => import('../pages/Charts/ChartjsChart'))
const EChart = lazy(() => import('../pages/Charts/EChart'))
const SparklineChart = lazy(() => import('../pages/Charts/SparklineChart'))

// Maps
const MapsGoogle = lazy(() => import('../pages/Maps/MapsGoogle'))
const MapsVector = lazy(() => import('../pages/Maps/MapsVector'))
const MapsLeaflet = lazy(() => import('../pages/Maps/MapsLeaflet'))

//Icons
const IconDripicons = lazy(() => import('../pages/Icons/IconDripicons'))
const IconMaterialdesign = lazy(() => import('../pages/Icons/IconMaterialdesign'))
const TypiconsIcon = lazy(() => import('../pages/Icons/IconTypicons'))
const IconIon = lazy(() => import('../pages/Icons/IconIon'))
const ThemifyIcon = lazy(() => import('../pages/Icons/IconThemify'))
const IconFontawesome = lazy(() => import('../pages/Icons/IconFontawesome'))

//Tables
const BasicTables = lazy(() => import('../pages/Tables/BasicTables'))
const DatatableTables = lazy(() => import('../pages/Tables/DatatableTables'))
const ResponsiveTables = lazy(() => import('../pages/Tables/ResponsiveTables'))
const EditableTables = lazy(() => import('../pages/Tables/EditableTables'))

// Forms
const FormElements = lazy(() => import('../pages/Forms/FormElements'))
const FormAdvanced = lazy(() => import('../pages/Forms/FormAdvanced'))
const FormEditors = lazy(() => import('../pages/Forms/FormEditors'))
const FormValidations = lazy(() => import('../pages/Forms/FormValidations'))
const FormMask = lazy(() => import('../pages/Forms/FormMask'))
const FormRepeater = lazy(() => import('../pages/Forms/FormRepeater'))
const FormUpload = lazy(() => import('../pages/Forms/FormUpload'))
const FormWizard = lazy(() => import('../pages/Forms/FormWizard'))
const FormXeditable = lazy(() => import('../pages/Forms/FormXeditable'))

//Ui
const UiAlert = lazy(() => import('../pages/Ui/UiAlert'))
const UiButtons = lazy(() => import('../pages/Ui/UiButtons'))
const UiCards = lazy(() => import('../pages/Ui/UiCards'))
const UiCarousel = lazy(() => import('../pages/Ui/UiCarousel'))
const UiColors = lazy(() => import('../pages/Ui/UiColors'))
const UiDropdown = lazy(() => import('../pages/Ui/UiDropdown'))
const UiGeneral = lazy(() => import('../pages/Ui/UiGeneral'))
const UiGrid = lazy(() => import('../pages/Ui/UiGrid'))
const UiImages = lazy(() => import('../pages/Ui/UiImages'))
const UiLightbox = lazy(() => import('../pages/Ui/UiLightbox'))
const UiModal = lazy(() => import('../pages/Ui/UiModal'))
const UiProgressbar = lazy(() => import('../pages/Ui/UiProgressbar'))
const UiSweetAlert = lazy(() => import('../pages/Ui/UiSweetAlert'))
const UiTabsAccordions = lazy(() => import('../pages/Ui/UiTabsAccordions'))
const UiTypography = lazy(() => import('../pages/Ui/UiTypography'))
const UiVideo = lazy(() => import('../pages/Ui/UiVideo'))
const UiSessionTimeout = lazy(() => import('../pages/Ui/UiSessionTimeout'))
const UiRating = lazy(() => import('../pages/Ui/UiRating'))
const UiRangeSlider = lazy(() => import('../pages/Ui/UiRangeSlider'))

//Pages
const PagesStarter = lazy(() => import('../pages/Utility/pages-starter'))
const PagesMaintenance = lazy(() => import('../pages/Utility/pages-maintenance'))
const PagesComingsoon = lazy(() => import('../pages/Utility/pages-comingsoon'))
const PagesTimeline = lazy(() => import('../pages/Utility/pages-timeline'))
const PagesInvoice = lazy(() => import('../pages/Utility/PagesInvoice'))
const PagesFaqs = lazy(() => import('../pages/Utility/pages-faqs'))
const PagesPricing = lazy(() => import('../pages/Utility/pages-pricing'))
const Pages404 = lazy(() => import('../pages/Utility/pages-404'))
const Pages500 = lazy(() => import('../pages/Utility/pages-500'))
const PagesGallery = lazy(() => import('../pages/Utility/PagesGallery'))
const PagesDirectory = lazy(() => import('../pages/Utility/PagesDirectory'))

const Overview = lazy(() => import('allPages/dashboard/Overview'))
const Customer = lazy(() => import('allPages/dashboard/Customer'))
const Pro = lazy(() => import('allPages/dashboard/Pro'))
const Footprint = lazy(() => import('allPages/dashboard/Footprint'))

const GraylistUsers = lazy(() => import('allPages/graylistUsers/GraylistUsers'))

const GraylistContent = lazy(() => import('allPages/graylistContent/GraylistContent'))

const Contents = lazy(() => import('allPages/Contents'))
const ContentDetails = lazy(() => import('allPages/Contents/ContentDetails'))
const AddContent = lazy(() => import('allPages/Contents/AddContent'))

const TicketsTable = lazy(() => import('allPages/Support/TicketsTable'))
const TicketAdd = lazy(() => import('allPages/Support/TicketAdd'))
const TicketDetail = lazy(() => import('allPages/Support/TicketDetail'))

const Invoices = lazy(() => import('allPages/Support/Invoices'))
const InvoiceAdd = lazy(() => import('allPages/Support/InvoiceAdd'))
const InvoicePreview = lazy(() => import('allPages/Support/InvoicePreview'))
const InvoiceDetail = lazy(() => import('allPages/Support/InvoiceDetail'))

const Announcements = lazy(() => import('allPages/Support/Announcements'))
const AnnouncementDetail = lazy(() => import('allPages/Support/AnnouncementDetail'))
const AnnouncementAdd = lazy(() => import('allPages/Support/AnnouncementAdd'))

const User = lazy(() => import('allPages/User'))
const UserDetails = lazy(() => import('allPages/User/UserDetails'))
const AddNewUser = lazy(() => import('allPages/User/AddNewUser'))

const Managers = lazy(() => import('allPages/System/Managers'))
const ManagerAdd = lazy(() => import('allPages/System/ManagerAdd'))
const ManagerDetail = lazy(() => import('allPages/System/ManagerDetail'))

// ====== temporary ===
const LoginPage = lazy(() => import('allPages/LoginPage'))

const userRoutes = [
  { path: '/dashboard', component: Overview },
  { path: '/footprint', component: Footprint },
  { path: '/customer/overview', component: Customer },
  { path: '/pro/overview', component: Pro },

  { path: '/pros', component: User },
  { path: '/pros/add', component: AddNewUser },
  { path: '/pros/info/:id', component: UserDetails },

  { path: '/customers', component: User },
  { path: '/customers/add', exact: true, component: AddNewUser },
  { path: '/customers/info/:id', exact: true, component: UserDetails },

  { path: '/graylist-user', component: GraylistUsers },
  { path: '/graylist-user/info/:id', component: UserDetails },

  { path: '/contents', component: Contents },
  { path: '/contents/:id', component: ContentDetails },
  { path: '/contents/add/new', component: AddContent },
  { path: '/contents/add/new/:id', component: AddContent },

  { path: '/graylist-content', component: GraylistContent },

  { path: '/graylist-content/info/:id', component: ContentDetails },

  { path: '/tickets', component: TicketsTable },
  { path: '/tickets/add', component: TicketAdd },
  { path: '/tickets/info/:id', component: TicketDetail },

  { path: '/invoices', component: Invoices },
  { path: '/invoices/add', component: InvoiceAdd },
  { path: '/invoices/preview/:id', component: InvoicePreview },
  { path: '/invoices/info/:id', component: InvoiceDetail },

  { path: '/announcements', component: Announcements },
  { path: '/announcements/add', component: AnnouncementAdd },
  { path: '/announcements/info/:id', component: AnnouncementDetail },

  { path: '/managers', component: Managers },
  { path: '/managers/add', component: ManagerAdd },
  { path: '/managers/info/:id', component: ManagerDetail },

  { path: '/login-page', component: LoginPage },
  // -------------

  { path: '/dboard', component: Dashboard },

  // //calendar
  { path: '/calendar', component: Calendar },

  // //profile
  { path: '/profile', component: UserProfile },

  //Email
  { path: '/email-inbox', component: EmailInbox },
  { path: '/email-read', component: EmailRead },
  { path: '/email-compose', component: EmailCompose },

  // Email Template
  { path: '/email-template-alert', component: Emailtemplatealert },
  { path: '/email-template-basic', component: Emailtemplatebasic },
  { path: '/email-template-billing', component: Emailtemplatebilling },

  //Charts
  { path: '/apex-charts', component: ChartApex },
  { path: '/chartist-charts', component: ChartistChart },
  { path: '/chartjs-charts', component: ChartjsChart },
  { path: '/e-charts', component: EChart },
  { path: '/sparkline-charts', component: SparklineChart },

  // Icons
  { path: '/icons-dripicons', component: IconDripicons },
  { path: '/icons-materialdesign', component: IconMaterialdesign },
  { path: '/icons-fontawesome', component: IconFontawesome },
  { path: '/icons-ion', component: IconIon },
  { path: '/icons-themify', component: ThemifyIcon },
  { path: '/icons-typicons', component: TypiconsIcon },

  // Tables
  { path: '/tables-basic', component: BasicTables },
  { path: '/tables-datatable', component: DatatableTables },
  { path: '/tables-responsive', component: ResponsiveTables },
  { path: '/tables-editable', component: EditableTables },

  // Maps
  { path: '/maps-google', component: MapsGoogle },
  { path: '/maps-vector', component: MapsVector },
  { path: '/maps-leaflet', component: MapsLeaflet },

  // Forms
  { path: '/form-elements', component: FormElements },
  { path: '/form-advanced', component: FormAdvanced },
  { path: '/form-editors', component: FormEditors },
  { path: '/form-mask', component: FormMask },
  { path: '/form-repeater', component: FormRepeater },
  { path: '/form-uploads', component: FormUpload },
  { path: '/form-wizard', component: FormWizard },
  { path: '/form-validation', component: FormValidations },
  { path: '/form-xeditable', component: FormXeditable },

  // Ui
  { path: '/ui-alerts', component: UiAlert },
  { path: '/ui-buttons', component: UiButtons },
  { path: '/ui-cards', component: UiCards },
  { path: '/ui-carousel', component: UiCarousel },
  { path: '/ui-colors', component: UiColors },
  { path: '/ui-dropdowns', component: UiDropdown },
  { path: '/ui-general', component: UiGeneral },
  { path: '/ui-grid', component: UiGrid },
  { path: '/ui-images', component: UiImages },
  { path: '/ui-lightbox', component: UiLightbox },
  { path: '/ui-modals', component: UiModal },
  { path: '/ui-progressbars', component: UiProgressbar },
  { path: '/ui-sweet-alert', component: UiSweetAlert },
  { path: '/ui-tabs-accordions', component: UiTabsAccordions },
  { path: '/ui-typography', component: UiTypography },
  { path: '/ui-video', component: UiVideo },
  { path: '/ui-session-timeout', component: UiSessionTimeout },
  { path: '/ui-rating', component: UiRating },
  { path: '/ui-rangeslider', component: UiRangeSlider },

  //Utility
  { path: '/pages-starter', component: PagesStarter },
  { path: '/pages-timeline', component: PagesTimeline },
  { path: '/pages-invoice', component: PagesInvoice },
  { path: '/pages-directory', component: PagesDirectory },
  { path: '/pages-faqs', component: PagesFaqs },
  { path: '/pages-pricing', component: PagesPricing },
  { path: '/pages-gallery', component: PagesGallery },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> }
]

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password/:paraEmail', component: ForgetPwd },
  { path: '/verify-code/:paraEmail', component: VerifyCode },
  { path: '/reset-password/:paraEmail/:paraOtp', component: ResetPassword },
  { path: '/change-password', component: ChangePassword },

  { path: '/verify-mfa', component: VerifyMFA },

  { path: '/register', component: Register },

  { path: '/pages-maintenance', component: PagesMaintenance },
  { path: '/pages-comingsoon', component: PagesComingsoon },
  { path: '/pages-404', component: Pages404 },
  { path: '/pages-500', component: Pages500 },

  // Authentication Inner
  { path: '/pages-login', component: Login1 },
  { path: '/pages-login-2', component: Login2 },
  { path: '/pages-register', component: Register1 },
  { path: '/pages-register-2', component: Register2 },
  { path: '/page-recoverpw', component: Recoverpw },
  { path: '/page-recoverpw-2', component: Recoverpw2 },
  { path: '/pages-forgot-pwd', component: ForgetPwd1 },
  { path: '/auth-lock-screen', component: LockScreen },
  { path: '/auth-lock-screen-2', component: LockScreen2 },
  { path: '/page-confirm-mail', component: ConfirmMail },
  { path: '/page-confirm-mail-2', component: ConfirmMail2 },
  { path: '/auth-email-verification', component: EmailVerification },
  { path: '/auth-email-verification-2', component: EmailVerification2 },
  { path: '/auth-two-step-verification', component: TwostepVerification },
  { path: '/auth-two-step-verification-2', component: TwostepVerification2 }
]

export { userRoutes, authRoutes }
