import axios from 'axios'

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({
  baseURL: API_URL
})

axiosApi.interceptors.request.use(function (config) {
  const authUser = localStorage.getItem('authUser')
  if (authUser) {
    const _authUser = JSON.parse(authUser)
    if (_authUser.token) config.headers.Authorization = `Bearer ${_authUser.token}`
  }
  return config
})

axiosApi.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status && [401, 403, 406].includes(error.response.status)) {
      localStorage.clear()
      window.location.href = '/'
      return true
    }
    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config })
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then((response) => response)
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then((response) => response)
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config }).then((response) => response.data)
}
