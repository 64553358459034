export const GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO'
export const SET_DASHBOARD_INFO = 'SET_DASHBOARD_INFO'

export const GET_REGISTERED_USERS_CHART = 'GET_REGISTERED_USERS_CHART'
export const SET_REGISTERED_USERS_CHART = 'SET_REGISTERED_USERS_CHART'

export const GET_ACTIVE_USERS_CHART = 'GET_ACTIVE_USERS_CHART'
export const SET_ACTIVE_USERS_CHART = 'SET_ACTIVE_USERS_CHART'

export const GET_USERS_MAP_CHART = 'GET_USERS_MAP_CHART'
export const SET_USERS_MAP_CHART = 'SET_USERS_MAP_CHART'
