import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { connect } from 'react-redux'
import { Form, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Input, Button } from 'reactstrap'

import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Import menuDropdown
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown'
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown'
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'

// import logodarkImg from "../../assets/images/logo-dark.png";
// import logosmImg from "../../assets/images/logo-sm.png";
// import logolightImg from "../../assets/images/logo-light.png";
import nearProLogo from '../../assets/images/nearProLogo.png'
import fingerTouchIcon from '../../assets/images/fingerTouch.png'
import searchIcon from '../../assets/images/searchIcon.png'

//i18n
import { withTranslation } from 'react-i18next'

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from '../../store/actions'

const Header = (props) => {
  const [search, setsearch] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const { menuData } = useSelector((state) => state.MenuHeader)
  const headerDynamic = () => {
    switch (menuData.header) {
      case 'dashboard':
        return 'DASHBOARD'

      case 'pros':
        return 'ACCOUNTS'

      case 'customers':
        return 'ACCOUNTS'

      case 'graylist':
        return 'ACCOUNTS'

      case 'graylist-user':
        return 'ACCOUNTS'

      case 'contents':
        return 'CONTENT'

      case 'graylist-content':
        return 'CONTENT'

      case 'tickets':
        return 'SUPPORT'

      case 'invoices':
        return 'SUPPORT'

      case 'announcements':
        return 'SUPPORT'

      case 'managers':
        return 'SYSTEM'

      default:
        return 'DASHBOARD'
    }
  }

  // function toggleFullscreen() {
  //   if (
  //     !document.fullscreenElement &&
  //     /* alternative standard method */ !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement
  //   ) {
  //     // current working methods
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen()
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen()
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       document.documentElement.webkitRequestFullscreen(
  //         Element.ALLOW_KEYBOARD_INPUT
  //       )
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen()
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen()
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen()
  //     }
  //   }
  // }

  // function tToggle() {
  //   var body = document.body;
  //   if (window.screen.width <= 992) {
  //     body.classList.toggle("sidebar-enable");
  //   } else {
  //     body.classList.toggle("vertical-collpsed");
  //     body.classList.toggle("sidebar-enable");
  //   }
  // }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <div className="navbar-brand-box">
              {/* <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosmImg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logodarkImg} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logosmImg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logolightImg} alt="" height="18" />
                </span>
              </Link> */}
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={nearProLogo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={nearProLogo} alt="" height="62px" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={nearProLogo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={nearProLogo} alt="" height="62px" />
                </span>
              </Link>
            </div>

            {/* <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={() => {
                tToggle()
              }}
              data-target="#topnav-menu-content"
            >
              <i className="mdi mdi-menu"></i>
            </button> */}

            {/* <div className="d-none d-sm-block">
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
                className="pt-3 d-inline-block">
                <DropdownToggle className="btn btn-secondary" caret>
                  Create <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>Action</DropdownItem>
                  <DropdownItem>Another action</DropdownItem>
                  <DropdownItem>Something else here</DropdownItem>
                  <div className="dropdown-divider"></div>
                  <DropdownItem>Separated link</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div> */}

            <div style={{ marginLeft: '45px' }} className="d-none d-sm-block text-dark">
              <h5>{headerDynamic()}</h5>
            </div>
          </div>

          <div style={{ gap: '15px' }} className="d-flex">
            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Search..."}
                />
                <span className="fa fa-search"></span>
              </div>
            </form> */}

            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  alert('clicked search')
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="">
                  <img src={searchIcon} alt="" height={'30px'} />
                </i>
              </button>
            </div>

            <Dropdown
              className="d-inline-block d-lg-none ms-2"
              onClick={() => {
                setsearch(!search)
              }}
              type="button"
            >
              <DropdownToggle className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" tag="button">
                {' '}
                <i className="mdi mdi-magnify"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>

            {/* <LanguageDropdown /> */}

            {/* <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen"></i>
              </button>
            </div> */}

            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  alert('clicked')
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="">
                  <img src={fingerTouchIcon} alt="" height={'30px'} />
                </i>
              </button>
            </div>

            {/* <NotificationDropdown /> */}

            <ProfileMenu />

            {/* <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar)
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="mdi mdi-cog-outline"></i>
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
})(withTranslation()(Header))
