import { call, put, select, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SEND_MFA, VERIFY_MFA } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"

import cogoTost from "cogo-toast"
import { post } from "helpers/api_helper"
import { setPermissions, setProfile } from "../profile/actions"

function* loginUser({ payload: { user, history, setSubmitting } }) {
  try {
    const { data } = yield call(post, "/admin/auth/login", user)
    const { permissions, ..._user } = data.data
    localStorage.setItem("authUser", JSON.stringify(_user))
    localStorage.setItem("permissions", JSON.stringify(permissions))
    yield put(loginSuccess(data.data))
    yield put(setProfile(_user))
    yield put(setPermissions(permissions))
    yield call(post, "/admin/auth/send-mfa", { type: 2 })
    history.push("/verify-mfa")
    // history.push("/dashboard")
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* sendMFA({ payload: { setLoading } }) {
  try {
    setLoading(true)
    const { data } = yield call(post, "/admin/auth/send-mfa", { type: 2 })
    cogoTost.success(data.message)
    setLoading(false)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setLoading(false)
  }
}

function* verifyMFA({ payload: { value, history, setSubmitting } }) {
  try {
    const user = yield select(state => state.Profile.user)
    const { data } = yield call(post, "/admin/auth/verify-mfa", value)
    if (user.isTempPassword) {
      cogoTost.success(data.message)
      history.push(`/change-password`)
    } else {
      cogoTost.success("Login Succesfully")
      history.push(`/dashboard`)
    }
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.clear()
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(SEND_MFA, sendMFA)
  yield takeEvery(VERIFY_MFA, verifyMFA)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
