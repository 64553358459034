import {
  ADD_CONTENTS,
  GET_CONTENTS,
  GET_CONTENTS_INFO,
  GET_CONTENT_CITIES,
  GET_CONTENT_INTERACTIONS,
  GET_CONTENT_INTERACTIONS_CITY,
  GET_CONTENT_INTERACTIONS_STATE,
  GET_CONTENT_INTERACTIONS_ZIPCODES,
  GET_CONTENT_STATES,
  GET_CONTENT_ZIPCODES,
  SET_CONTENTS,
  SET_CONTENTS_INFO,
  SET_CONTENT_CITIES,
  SET_CONTENT_INTERACTIONS,
  SET_CONTENT_INTERACTIONS_CITY,
  SET_CONTENT_INTERACTIONS_STATE,
  SET_CONTENT_INTERACTIONS_ZIPCODES,
  SET_CONTENT_STATES,
  SET_CONTENT_ZIPCODES,
  UPDATE_CONTENTS,
  GET_CONTENT_GRAYLIST,
  SET_CONTENT_GRAYLIST,
  CHANGE_CONTENT_GRAYLIST_STATUS,
} from "./actionTypes"

export const getContentsStates = () => ({ type: GET_CONTENT_STATES })
export const setContentsStates = states => ({
  type: SET_CONTENT_STATES,
  payload: states,
})

export const getContentsCities = () => ({ type: GET_CONTENT_CITIES })
export const setContentsCities = cities => ({
  type: SET_CONTENT_CITIES,
  payload: cities,
})

export const getContentsZipcodes = () => ({ type: GET_CONTENT_ZIPCODES })
export const setContentsZipcodes = zipcodes => ({
  type: SET_CONTENT_ZIPCODES,
  payload: zipcodes,
})

export const getContents = payload => ({ type: GET_CONTENTS, payload })
export const setContents = users => ({ type: SET_CONTENTS, payload: users })

export const updateContents = payload => ({ type: UPDATE_CONTENTS, payload })
export const addContents = payload => ({ type: ADD_CONTENTS, payload })

export const getContentsInfo = payload => ({ type: GET_CONTENTS_INFO, payload })
export const setContentsInfo = content => ({
  type: SET_CONTENTS_INFO,
  payload: content,
})

export const getContentInteractionsCities = payload => ({
  type: GET_CONTENT_INTERACTIONS_CITY,
  payload,
})
export const setContentInteractionsCities = users => ({
  type: SET_CONTENT_INTERACTIONS_CITY,
  payload: users,
})

export const getContentInteractionsStates = payload => ({
  type: GET_CONTENT_INTERACTIONS_STATE,
  payload,
})
export const setContentInteractionsStates = users => ({
  type: SET_CONTENT_INTERACTIONS_STATE,
  payload: users,
})

export const getContentInteractionsZipcodes = payload => ({
  type: GET_CONTENT_INTERACTIONS_ZIPCODES,
  payload,
})
export const setContentInteractionsZipcodes = users => ({
  type: SET_CONTENT_INTERACTIONS_ZIPCODES,
  payload: users,
})

export const getContentInteractions = payload => ({
  type: GET_CONTENT_INTERACTIONS,
  payload,
})
export const setContentInteractions = payload => ({
  type: SET_CONTENT_INTERACTIONS,
  payload: payload,
})

export const changeContentGraylistStatus = payload => ({
  type: CHANGE_CONTENT_GRAYLIST_STATUS,
  payload,
})

export const getContentGraylist = payload => ({
  type: GET_CONTENT_GRAYLIST,
  payload,
})
export const setContentGraylist = glist => ({
  type: SET_CONTENT_GRAYLIST,
  payload: glist,
})
