import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  SEND_MFA,
  VERIFY_MFA,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const loginUser = (user, history, setSubmitting) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, setSubmitting },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const sendMFA = setLoading => {
  return {
    type: SEND_MFA,
    payload: { setLoading },
  }
}

export const verifyMFA = (value, history, setSubmitting) => {
  return {
    type: VERIFY_MFA,
    payload: { value, history, setSubmitting },
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
