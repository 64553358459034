import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  SET_PROFILE,
  SET_PERMISSIONS,
} from "./actionTypes"

const defaultPermissions = {
  overview: {
    viewOnly: true,
    allAccess: true,
  },
  customers: {
    viewOnly: true,
    allAccess: true,
  },
  pros: {
    viewOnly: true,
    allAccess: true,
  },
  graylist: {
    viewOnly: true,
    allAccess: true,
  },
  content: {
    viewOnly: true,
    allAccess: true,
  },
  contentGraylist: {
    viewOnly: true,
    allAccess: true,
  },
  tickets: {
    viewOnly: true,
    allAccess: true,
  },
  invoices: {
    viewOnly: true,
    allAccess: true,
  },
  announcements: {
    viewOnly: true,
    allAccess: true,
  },
  managers: {
    viewOnly: true,
    allAccess: true,
  },
  defaultContent: {
    viewOnly: true,
    allAccess: true,
  },
}

const getPermissions = () => {
  try {
    const permissions = localStorage.getItem("permissions")
    if (permissions) {
      return JSON.parse(permissions)
    }
    return defaultPermissions
  } catch (error) {
    return defaultPermissions
  }
}

const getUser = () => {
  try {
    const authUser = localStorage.getItem("authUser")
    return JSON.parse(authUser)
  } catch (error) {
    return {}
  }
}

const initialState = {
  error: "",
  success: "",
  user: getUser(),
  permissions: getPermissions(),
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break

    case SET_PROFILE:
      state = { ...state, user: action.payload }
      break

    case SET_PERMISSIONS:
      state = { ...state, permissions: action.payload }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
