import {
  SET_HELPS,
  SET_HELP_INFO,
  SET_INVOICES,
  SET_INVOICE_INFO,
  SET_ALL_STATES,
  // SET_ALL_ZIPCODES,
  SET_ANNOUNCEMENTS,
  SET_ANNOUNCEMENT_INFO
} from './actionTypes'

const INIT_STATE = {
  helps: {
    isLoading: false,
    current: 0,
    pages: 0,
    total: 0,
    records: [],
    from: 0,
    to: 0
  },
  helpInfo: {
    isLoading: true,
    data: null
  },

  invoices: {
    isLoading: false,
    current: 0,
    pages: 0,
    total: 0,
    records: [],
    from: 0,
    to: 0
  },
  invoiceInfo: {
    isLoading: true,
    data: null
  },

  allStates: [],
  // allZipcodes: [],
  announcements: {
    isLoading: false,
    current: 0,
    pages: 0,
    total: 0,
    records: [],
    from: 0,
    to: 0
  },
  announcementInfo: {
    isLoading: true,
    data: null
  }
}

const Help = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_HELPS:
      return {
        ...state,
        helps: {
          ...state.helps,
          ...action.payload
        }
      }

    case SET_HELP_INFO:
      return {
        ...state,
        helpInfo: {
          ...state.helpInfo,
          ...action.payload
        }
      }

    case SET_INVOICES:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          ...action.payload
        }
      }

    case SET_INVOICE_INFO:
      return {
        ...state,
        invoiceInfo: {
          ...state.invoiceInfo,
          ...action.payload
        }
      }

    case SET_ALL_STATES:
      return { ...state, allStates: action.payload }

    // case SET_ALL_ZIPCODES:
    //   return { ...state, allZipcodes: action.payload }

    case SET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          ...action.payload
        }
      }

    case SET_ANNOUNCEMENT_INFO:
      return {
        ...state,
        announcementInfo: {
          ...state.announcementInfo,
          ...action.payload
        }
      }

    default:
      return state
  }
}

export default Help
