import "./index.scss"

const SuspenseLoader = () => {
  return (
    <div className="suspense-loader">
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  )
}

export default SuspenseLoader
