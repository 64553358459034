import { GET_DASHBOARD_INFO, SET_DASHBOARD_INFO, GET_ACTIVE_USERS_CHART, GET_REGISTERED_USERS_CHART, GET_USERS_MAP_CHART, SET_ACTIVE_USERS_CHART, SET_REGISTERED_USERS_CHART, SET_USERS_MAP_CHART } from './actionTypes'

export const getDashboardInfo = () => ({ type: GET_DASHBOARD_INFO })
export const setDashboardInfo = (dashbInfo) => ({
  type: SET_DASHBOARD_INFO,
  payload: dashbInfo
})

export const getRegisteredUsers = (payload) => ({
  type: GET_REGISTERED_USERS_CHART,
  payload
})
export const setRegisteredUsers = (payload) => ({
  type: SET_REGISTERED_USERS_CHART,
  payload
})

export const getActiveUsers = (payload) => ({
  type: GET_ACTIVE_USERS_CHART,
  payload
})
export const setActiveUsers = (payload) => ({
  type: SET_ACTIVE_USERS_CHART,
  payload
})

export const setUserMap = (payload) => ({
  type: SET_USERS_MAP_CHART,
  payload
})

export const getUserMap = () => ({ type: GET_USERS_MAP_CHART })
