import {
  GET_HELPS,
  SET_HELPS,
  GET_HELP_INFO,
  SET_HELP_INFO,
  UPDATE_HELP_STATUS,
  DELETE_TICKETS,
  ADD_TICKETS,
  GET_INVOICES,
  SET_INVOICES,
  GET_INVOICE_INFO,
  SET_INVOICE_INFO,
  GET_ALL_STATES,
  SET_ALL_STATES,
  // GET_ALL_ZIPCODES,
  // SET_ALL_ZIPCODES,
  ADD_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS,
  SET_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENT_STATUS,
  DELETE_ANNOUNCEMENTS,
  GET_ANNOUNCEMENT_INFO,
  SET_ANNOUNCEMENT_INFO,
  UPDATE_ANNOUNCEMENT,
  ADD_INVOICES,
  UPDATE_INVOICES,
  DELETE_INVOICES,
  DELETE_INVOICE,
  REFUND_CONFIRM,
  REFUND_INVOICE_TRANSACTION,
  RECHARGE_INVOICE
} from './actionTypes'

export const getHelps = (payload) => ({ type: GET_HELPS, payload })
export const setHelps = (helps) => ({ type: SET_HELPS, payload: helps })

export const getHelpInfo = (payload) => ({ type: GET_HELP_INFO, payload })
export const refundConfirm = (payload) => ({ type: REFUND_CONFIRM, payload })
export const setHelpInfo = (helps) => ({ type: SET_HELP_INFO, payload: helps })

export const updateHelpStatus = (payload) => ({
  type: UPDATE_HELP_STATUS,
  payload
})

export const deleteTickets = (payload) => ({
  type: DELETE_TICKETS,
  payload
})

export const addTicket = (payload) => ({ type: ADD_TICKETS, payload })

export const addInvoice = (payload) => ({ type: ADD_INVOICES, payload })
export const updateInvoice = (payload) => ({ type: UPDATE_INVOICES, payload })
export const refundInvoiceTransaction = (payload) => ({ type: REFUND_INVOICE_TRANSACTION, payload })

export const getInvoices = (payload) => ({ type: GET_INVOICES, payload })
export const setInvoices = (invoice) => ({ type: SET_INVOICES, payload: invoice })

export const getInvoiceInfo = (payload) => ({ type: GET_INVOICE_INFO, payload })
export const setInvoiceInfo = (payload) => ({ type: SET_INVOICE_INFO, payload })
export const deleteInvoices = (payload) => ({ type: DELETE_INVOICES, payload })
export const deleteInvoice = (payload) => ({ type: DELETE_INVOICE, payload })
export const rechargeConfirm = (payload) => ({ type: RECHARGE_INVOICE, payload })

export const getAllStates = () => ({ type: GET_ALL_STATES })
export const setAllStates = (payload) => ({ type: SET_ALL_STATES, payload })
// export const getAllZipcodes = () => ({ type: GET_ALL_ZIPCODES })
// export const setAllZipcodes = zip => ({
//   type: SET_ALL_ZIPCODES,
//   payload: zip,
// })
export const addAnnouncement = (payload) => ({ type: ADD_ANNOUNCEMENT, payload })

export const getAnnouncements = (payload) => ({
  type: GET_ANNOUNCEMENTS,
  payload
})
export const setAnnouncements = (announcements) => ({
  type: SET_ANNOUNCEMENTS,
  payload: announcements
})

export const updateAnnouncementStatus = (payload) => ({
  type: UPDATE_ANNOUNCEMENT_STATUS,
  payload
})
export const deleteAnnouncements = (payload) => ({
  type: DELETE_ANNOUNCEMENTS,
  payload
})

export const getAnnouncementInfo = (payload) => ({
  type: GET_ANNOUNCEMENT_INFO,
  payload
})
export const setAnnouncementInfo = (announc) => ({
  type: SET_ANNOUNCEMENT_INFO,
  payload: announc
})

export const updateAnnouncement = (payload) => ({
  type: UPDATE_ANNOUNCEMENT,
  payload
})
