export const GET_STATES = "GET_STATES"
export const SET_STATES = "SET_STATES"

export const GET_CITIES = "GET_CITIES"
export const SET_CITIES = "SET_CITIES"

export const GET_ZIPCODES = "GET_ZIPCODES"
export const SET_ZIPCODES = "SET_ZIPCODES"

export const GET_USERS = "GET_USERS"
export const SET_USERS = "SET_USERS"

export const GET_USERS_INFO = "GET_USERS_INFO"
export const SET_USERS_INFO = "SET_USERS_INFO"

export const GET_USER_CONTENT = "GET_USER_CONTENT"
export const SET_USER_CONTENT = "SET_USER_CONTENT"

export const GET_USER_CONTENTS_CITY = "GET_USER_CONTENTS_CITY"
export const SET_USER_CONTENTS_CITY = "SET_USER_CONTENTS_CITY"

export const GET_USER_CONTENTS_STATE = "GET_USER_CONTENTS_STATE"
export const SET_USER_CONTENTS_STATE = "SET_USER_CONTENTS_STATE"

export const GET_USER_CONTENTS_ZIPCODES = "GET_USER_CONTENTS_ZIPCODES"
export const SET_USER_CONTENTS_ZIPCODES = "SET_USER_CONTENTS_ZIPCODES"

export const UPDATE_DETAIL = "UPDATE_DETAIL"

export const ADD_USER = "ADD_USER"
