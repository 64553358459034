import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
// import user1 from "../../../assets/images/users/user-4.jpg"
import userLogo from "../../../assets/images/users/user.png"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const { user } = useSelector(state => state.Profile)
  const [profile, setProfile] = useState("")

  useEffect(() => {
    setProfile(user)
  }, [user])

  // const [username, setusername] = useState("")
  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"))
  //       setusername(obj.displayName)
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"))
  //       setusername(obj.username)
  //     }
  //   }
  // }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img className="header-profile-user" src={userLogo} />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end profilerMenu">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div
            style={{ gap: "10px" }}
            className="d-flex justify-content-start align-items-center pd_5_20"
          >
            <img src={userLogo} alt="user" height={30} width={30} />
            <div className="d-flex flex-column">
              <span className="usernName">
                {profile ? profile.firstName + " " + profile.lastName : "Admin"}
              </span>
              <span className="userEmail">{profile.email || "email"}</span>
            </div>
          </div>
          <div
            style={{ borderTopColor: "#858585" }}
            className="dropdown-divider"
          />
          <Link
            to="/logout"
            className="dropdown-item logoutLink text-center p-0"
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Log Out")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      {menu ? <div className="modal-backdrop fade show"></div> : null}
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
