import { SET_DASHBOARD_INFO, SET_ACTIVE_USERS_CHART, SET_REGISTERED_USERS_CHART, SET_USERS_MAP_CHART } from './actionTypes'

const INIT_STATE = {
  dashboardInfo: {
    isLoading: false,
    data: null
  },
  registeredUsers: { registerLabels: [], registerChartData: [] },
  newRegisterUsers: { newRegisterLabels: [], newRegisterChartData: [] },
  isLoading: false,
  activeUsers: {
    acticeUsersLabels: [],
    acticeUsersChartData: [],
    isLoading: false
  },
  usersMap: {
    data: [],
    total: 0,
    loading: false,
    header: {
      city: null,
      cityMaaCounts: null,
      state: null,
      stateMaaCounts: null,
      zipcode: null,
      zipcodeMaaCounts: null,
      city30d: null,
      city30dMaaCounts: null,
      state30d: null,
      state30dMaaCounts: null,
      zipcode30d: null,
      zipcode30dMaaCounts: null
    }
  }
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DASHBOARD_INFO:
      return {
        ...state,
        dashboardInfo: {
          ...state.dashboardInfo,
          ...action.payload
        }
      }

    case SET_REGISTERED_USERS_CHART:
      return {
        ...state,
        registeredUsers: action.payload.registeredUsers,
        newRegisterUsers: action.payload.newRegisterUsers,
        isLoading: action.payload.isLoading
      }

    case SET_ACTIVE_USERS_CHART:
      return {
        ...state,
        activeUsers: {
          ...state.activeUsers,
          ...action.payload.activeUsers
        }
      }

    case SET_USERS_MAP_CHART:
      return {
        ...state,
        usersMap: { ...state.usersMap, ...action.payload.usersMap }
      }

    default:
      return state
  }
}

export default Dashboard
