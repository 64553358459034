import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  VERIFY_CODE,
  RESEND_OTP,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
} from "./actionTypes"

export const userForgetPassword = (value, history, setSubmitting) => {
  return {
    type: FORGET_PASSWORD,
    payload: { value, history, setSubmitting },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const verifyCode = (value, history, setSubmitting) => {
  return {
    type: VERIFY_CODE,
    payload: { value, history, setSubmitting },
  }
}

export const resendOTP = (value, setLoading) => {
  return {
    type: RESEND_OTP,
    payload: { value, setLoading },
  }
}

export const resetPassword = (value, history, setSubmitting) => {
  return {
    type: RESET_PASSWORD,
    payload: { value, history, setSubmitting },
  }
}

export const changePassword = (value, history, setSubmitting) => ({
  type: CHANGE_PASSWORD,
  payload: { value, history, setSubmitting },
})
