import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import UserSaga from "./user/saga"
import ContentSaga from "./contents/saga"
import HelpSaga from "./support/saga"
import GraylistSaga from "./graylist/saga"
import DashboardSaga from "./dashboard/saga"
import ManagerSaga from "./manager/saga"

export default function* rootSaga() {
  yield all([
    //public
    ManagerSaga(),
    ContentSaga(),
    HelpSaga(),
    UserSaga(),
    GraylistSaga(),
    DashboardSaga(),
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(calendarSaga),
  ])
}
