export const ADD_MANAGER = "ADD_MANAGER"
export const SET_MANAGER = "SET_MANAGER"

export const GET_MANAGERS = "GET_MANAGERS"
export const SET_MANAGERS = "SET_MANAGERS"

export const SET_MANAGER_INFO = "SET_MANAGER_INFO"
export const GET_MANAGER_INFO = "GET_MANAGER_INFO"

export const UPDATE_MANAGER_INFO = "UPDATE_MANAGER_INFO"

export const SEND_DELETE_MFA = "SEND_DELETE_MFA"
export const DELETE_MANAGER = "DELETE_MANAGER"
