import { call, put, takeEvery } from "redux-saga/effects"
import cogoTost from "cogo-toast"
import { get, put as putMethod, post, del } from "helpers/api_helper"
import axios from "axios"
import {
  ADD_MANAGER,
  GET_MANAGERS,
  GET_MANAGER_INFO,
  UPDATE_MANAGER_INFO,
  SEND_DELETE_MFA,
  DELETE_MANAGER,
} from "./actionTypes"
import { setManagerId, setManagerInfo, setManagers } from "./actions"

function* addManager({ payload: { values, setSubmitting, history } }) {
  try {
    const { data } = yield call(post, `/admin/managers`, values)
    yield put(
      setManagerId({
        id: data.data.recordId,
        createdAt: data.data.createdAt,
      })
    )
    cogoTost.success(data.message)
    setSubmitting(false)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* getManagers({ payload: { body } }) {
  try {
    yield put(setManagers({ isLoading: true }))
    const { data } = yield call(post, `/admin/managers/list`, body)
    yield put(setManagers({ isLoading: false, ...data.data }))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getManagerInfo({ payload: { id } }) {
  try {
    yield put(setManagerInfo({ isLoading: true }))
    const { data } = yield call(get, `/admin/managers/${id}`)
    yield put(
      setManagerInfo({
        isLoading: false,
        ...data.data.user,
        ...data.data.permissions,
      })
    )
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* updateManagerInfo({ payload: { id, setSubmitting, values } }) {
  try {
    const { data } = yield call(putMethod, `/admin/managers/${id}`, values)
    cogoTost.success(data.message)
    setSubmitting(false)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* sendDeleteMFA({ payload: { event, setSinglebtn2 } }) {
  try {
    event.target.innerHTML = '<i class="fa fa-spinner fa-spin"></i>'
    const { data } = yield call(post, "/admin/auth/send-mfa", { type: 3 })
    cogoTost.success(data.message)
    setSinglebtn2(true)
    event.target.innerHTML = "Confirm"
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSinglebtn2(false)
    event.target.innerHTML = "Confirm"
  }
}

function* deleteManager({ payload: { id, mfaOtp, event, history } }) {
  try {
    event.target.innerHTML = '<i class="fa fa-spinner fa-spin"></i>'
    let otp = Number(mfaOtp)
    const { data } = yield call(post, `/admin/managers/delete/${id}`, { otp })
    cogoTost.success(data.message)
    event.target.innerHTML = "Deleted"
    history.push("/managers")
  } catch (error) {
    cogoTost.error(error.response.data.message)
    event.target.innerHTML = "Submit"
  }
}

function* ManagerSaga() {
  yield takeEvery(ADD_MANAGER, addManager)
  yield takeEvery(GET_MANAGERS, getManagers)
  yield takeEvery(GET_MANAGER_INFO, getManagerInfo)
  yield takeEvery(UPDATE_MANAGER_INFO, updateManagerInfo)
  yield takeEvery(SEND_DELETE_MFA, sendDeleteMFA)
  yield takeEvery(DELETE_MANAGER, deleteManager)
}

export default ManagerSaga
