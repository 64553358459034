export const GET_CONTENT_STATES = "GET_CONTENT_STATES"
export const SET_CONTENT_STATES = "SET_CONTENT_STATES"

export const GET_CONTENT_CITIES = "GET_CONTENT_CITIES"
export const SET_CONTENT_CITIES = "SET_CONTENT_CITIES"

export const GET_CONTENT_ZIPCODES = "GET_CONTENT_ZIPCODES"
export const SET_CONTENT_ZIPCODES = "SET_CONTENT_ZIPCODES"

export const GET_CONTENTS = "GET_CONTENTS"
export const SET_CONTENTS = "SET_CONTENTS"

export const UPDATE_CONTENTS = "UPDATE_CONTENTS"
export const ADD_CONTENTS = "ADD_CONTENTS"

export const GET_CONTENTS_INFO = "GET_CONTENTS_INFO"
export const SET_CONTENTS_INFO = "SET_CONTENTS_INFO"

export const GET_CONTENT_INTERACTIONS_CITY = "GET_CONTENT_INTERACTIONS_CITY"
export const SET_CONTENT_INTERACTIONS_CITY = "SET_CONTENT_INTERACTIONS_CITY"

export const GET_CONTENT_INTERACTIONS_STATE = "GET_CONTENT_INTERACTIONS_STATE"
export const SET_CONTENT_INTERACTIONS_STATE = "SET_CONTENT_INTERACTIONS_STATE"

export const GET_CONTENT_INTERACTIONS_ZIPCODES =
  "GET_CONTENT_INTERACTIONS_ZIPCODES"
export const SET_CONTENT_INTERACTIONS_ZIPCODES =
  "SET_CONTENT_INTERACTIONS_ZIPCODES"

export const GET_CONTENT_INTERACTIONS = "GET_CONTENT_INTERACTIONS"
export const SET_CONTENT_INTERACTIONS = "SET_CONTENT_INTERACTIONS"

export const GET_CONTENT_GRAYLIST = "GET_CONTENT_GRAYLIST"
export const SET_CONTENT_GRAYLIST = "SET_CONTENT_GRAYLIST"

export const CHANGE_CONTENT_GRAYLIST_STATUS = "CHANGE_CONTENT_GRAYLIST_STATUS"
