import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects'

// Login Redux States
import { FORGET_PASSWORD, VERIFY_CODE, RESEND_OTP, RESET_PASSWORD, CHANGE_PASSWORD } from './actionTypes'
import { userForgetPasswordSuccess, userForgetPasswordError } from './actions'

import cogoTost from 'cogo-toast'
import { post } from 'helpers/api_helper'
import { setProfile } from '../profile/actions'
//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"
// import {
//   postFakeForgetPwd,
//   postJwtForgetPwd,
// } from "../../../helpers/fakebackend_helper"

// const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* userForgetPassword({ payload: { value, history, setSubmitting } }) {
  try {
    const { data } = yield call(post, '/admin/auth/send-otp', value)
    cogoTost.success(data.message)
    yield put(userForgetPasswordSuccess(data))
    history && history.push(`/verify-code/${window.btoa(value.email)}`)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    yield put(userForgetPasswordError(error.response.data))
    setSubmitting && setSubmitting(false)
  }
  // try {
  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const response = yield call(fireBaseBackend.forgetPassword, user.email)
  //     if (response) {
  //       yield put(
  //         userForgetPasswordSuccess(
  //           "Reset link are sended to your mailbox, check there first"
  //         )
  //       )
  //     }
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
  //     const response = yield call(postJwtForgetPwd, "/jwt-forget-pwd", {
  //       email: user.email,
  //     })
  //     if (response) {
  //       yield put(
  //         userForgetPasswordSuccess(
  //           "Reset link are sended to your mailbox, check there first"
  //         )
  //       )
  //     }
  //   } else {
  //     const response = yield call(postFakeForgetPwd, "/fake-forget-pwd", {
  //       email: user.email,
  //     })
  //     if (response) {
  //       yield put(
  //         userForgetPasswordSuccess(
  //           "Reset link are sended to your mailbox, check there first"
  //         )
  //       )
  //     }
  //   }
  // } catch (error) {
  //   yield put(userForgetPasswordError(error))
  // }
}

function* verifyCode({ payload: { value, history, setSubmitting } }) {
  try {
    const { data } = yield call(post, '/admin/auth/verify-otp', value)
    cogoTost.success(data.message)
    history.push(`/reset-password/${window.btoa(value.email)}/${window.btoa(value.otp)}`)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* resendOTP({ payload: { value, setLoading } }) {
  try {
    setLoading(true)
    const { data } = yield call(post, '/admin/auth/send-otp', value)
    cogoTost.success(data.message)
    setLoading(false)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setLoading(false)
  }
}

function* resetPassword({ payload: { value, history, setSubmitting } }) {
  try {
    const { data } = yield call(post, '/admin/auth/change-password', value)
    cogoTost.success(data.message)
    history.push(`/login`)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

function* changePassword({ payload: { value, history, setSubmitting } }) {
  try {
    const user = yield select((state) => state.Profile.user)
    const { data } = yield call(post, '/admin/auth/reset-password', value)
    cogoTost.success(data.message)
    const { isTempPassword, ..._user } = user
    localStorage.setItem('authUser', JSON.stringify(_user))
    yield put(setProfile(_user))
    history.push(`/dashboard`)
  } catch (error) {
    cogoTost.error(error.response.data.message)
    setSubmitting(false)
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, userForgetPassword)
  yield takeEvery(VERIFY_CODE, verifyCode)
  yield takeEvery(RESEND_OTP, resendOTP)
  yield takeEvery(RESET_PASSWORD, resetPassword)
  yield takeEvery(CHANGE_PASSWORD, changePassword)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
