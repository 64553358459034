import {
  SET_CITIES,
  SET_STATES,
  SET_ZIPCODES,
  SET_USERS,
  SET_USERS_INFO,
  SET_USER_CONTENTS_CITY,
  SET_USER_CONTENTS_STATE,
  SET_USER_CONTENTS_ZIPCODES,
  SET_USER_CONTENT,
} from "./actionTypes"

const INIT_STATE = {
  userStates: [],
  userCities: [],
  userZipcodes: [],
  users: {
    isLoading: false,
    current: 0,
    pages: 0,
    total: 0,
    records: [],
    from: 0,
    to: 0,
  },
  user: {
    isLoading: false,
    data: null,
  },
  uContentCity: [],
  uContentState: [],
  uContentZipcodes: [],
  uContent: {
    isLoading: false,
    current: 0,
    pages: 0,
    total: 0,
    records: [],
    from: 0,
    to: 0,
  },
}

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_STATES:
      return { ...state, userStates: action.payload }

    case SET_CITIES:
      return { ...state, userCities: action.payload }

    case SET_ZIPCODES:
      return { ...state, userZipcodes: action.payload }

    case SET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload,
        },
      }

    case SET_USERS_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }

    case SET_USER_CONTENTS_CITY:
      return { ...state, uContentCity: action.payload }

    case SET_USER_CONTENTS_STATE:
      return { ...state, uContentState: action.payload }

    case SET_USER_CONTENTS_ZIPCODES:
      return { ...state, uContentZipcodes: action.payload }

    case SET_USER_CONTENT:
      return {
        ...state,
        uContent: {
          ...state.uContent,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default User
