import { SET_USERS_GRAYLIST, SET_GRAYLIST_ZIPCODE } from "./actionTypes"

const INIT_STATE = {
  graylist: {
    isLoading: true,
    data: null,
  },

  graylistZipcode: [],
}

const Graylist = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USERS_GRAYLIST:
      return {
        ...state,
        graylist: {
          ...state.graylist,
          ...action.payload,
        },
      }

    case SET_GRAYLIST_ZIPCODE:
      return { ...state, graylistZipcode: action.payload }

    default:
      return state
  }
}

export default Graylist
