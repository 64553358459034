import { call, put, takeEvery } from 'redux-saga/effects'
import cogoTost from 'cogo-toast'
import { get, post, put as putMethod } from 'helpers/api_helper'
import axios from 'axios'

import { setContentInteractions, setContentInteractionsCities, setContentInteractionsStates, setContentInteractionsZipcodes, setContents, setContentsCities, setContentsInfo, setContentsStates, setContentsZipcodes, setContentGraylist } from './actions'
import { ADD_CONTENTS, GET_CONTENTS, GET_CONTENTS_INFO, GET_CONTENT_CITIES, GET_CONTENT_INTERACTIONS, GET_CONTENT_INTERACTIONS_CITY, GET_CONTENT_INTERACTIONS_STATE, GET_CONTENT_INTERACTIONS_ZIPCODES, GET_CONTENT_STATES, GET_CONTENT_ZIPCODES, UPDATE_CONTENTS, GET_CONTENT_GRAYLIST, CHANGE_CONTENT_GRAYLIST_STATUS } from './actionTypes'

let usersToken = null
let interactionToken = null

function* getContentsStates() {
  try {
    const { data } = yield call(get, '/admin/posts/states')
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setContentsStates(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getContentsCities() {
  try {
    const { data } = yield call(get, '/admin/posts/cities')
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setContentsCities(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getContentsZipcodes() {
  try {
    const { data } = yield call(get, '/admin/posts/zipcodes')
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setContentsZipcodes(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getContents({ payload: { q } }) {
  try {
    if (usersToken) usersToken.cancel()
    usersToken = axios.CancelToken.source()
    yield put(setContents({ isLoading: true }))
    const { data } = yield call(get, `/admin/posts?${q}`, {
      cancelToken: usersToken.token
    })
    yield put(setContents({ ...data.data, isLoading: false }))
  } catch (error) {
    if (!axios.isCancel(error)) {
      cogoTost.error(error.response.data.message)
    }
  }
}

function* getContentsInfo({ payload: { id } }) {
  try {
    yield put(setContentsInfo({ isLoading: true, data: null }))
    const { data } = yield call(get, `/admin/posts/${id}`, {})
    yield put(setContentsInfo({ data: data.data, isLoading: false }))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getContentsInteractionsStates({ payload: { id } }) {
  try {
    const { data } = yield call(get, `/admin/posts/content-interactions/${id}/states`)
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setContentInteractionsStates(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getContentsInteractionsCities({ payload: { id } }) {
  try {
    const { data } = yield call(get, `/admin/posts/content-interactions/${id}/cities`)
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setContentInteractionsCities(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getContentsInteractionsZipcodes({ payload: { id } }) {
  try {
    const { data } = yield call(get, `/admin/posts/content-interactions/${id}/zipcodes`)
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setContentInteractionsZipcodes(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getContentInteractions({ payload: { id, q } }) {
  try {
    if (interactionToken) interactionToken.cancel()
    interactionToken = axios.CancelToken.source()
    yield put(setContentInteractions({ isLoading: true }))
    const { data } = yield call(get, `/admin/posts/content-interactions/${id}?${q}`, {
      cancelToken: interactionToken.token
    })
    yield put(setContentInteractions({ ...data.data, isLoading: false }))
  } catch (error) {
    if (!axios.isCancel(error)) {
      cogoTost.error(error.response.data.message)
    }
  }
}

function* updateContent({ payload: { values, setSubmitting, id, history, redir } }) {
  setSubmitting(true)
  try {
    const { data } = yield call(putMethod, `/admin/posts/${id}`, values)
    cogoTost.success(data.message)
    redir && history.push(redir)
  } catch (error) {
    cogoTost.error(error.response.data.message)
  } finally {
    setSubmitting(false)
  }
}

// function* addContents({ payload: { values, setSubmitting } }) {
//   setSubmitting(true)
//   try {
//     const { data } = yield call(post, `/admin/posts`, values)
//     cogoTost.success(data.message)
//   } catch (error) {
//     cogoTost.error(error.response.data.message)
//   } finally {
//     setSubmitting(false)
//   }
// }

function* changeContentGraylistStatus({ payload: { status, posts, update = false, getContents, getContentGraylist, getUserContent, id, q, setSinglebtn, history, redir } }) {
  try {
    const { data } = yield call(post, `/admin/posts/change-status`, {
      posts,
      status
    })
    if (setSinglebtn) setSinglebtn(false)
    if (update) {
      if (getContents) {
        yield put(getContents({ q }))
      } else if (getContentGraylist) {
        yield put(getContentGraylist({ q }))
      } else if (getUserContent) {
        yield put(getUserContent({ id, q }))
      }
      // setIds([])
      redir && history.push(redir)
      cogoTost.success(data.message)
    }
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getContentGraylist({ payload: { q } }) {
  try {
    yield put(setContentGraylist({ isLoading: true, data: null }))
    const { data } = yield call(get, `/admin/posts/graylisted?${q}`, {})
    yield put(setContentGraylist({ data: data.data, isLoading: false }))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* ContentSaga() {
  yield takeEvery(GET_CONTENT_STATES, getContentsStates)
  yield takeEvery(GET_CONTENT_CITIES, getContentsCities)
  yield takeEvery(GET_CONTENT_ZIPCODES, getContentsZipcodes)
  yield takeEvery(GET_CONTENTS, getContents)
  yield takeEvery(GET_CONTENTS_INFO, getContentsInfo)
  yield takeEvery(GET_CONTENT_INTERACTIONS_CITY, getContentsInteractionsCities)
  yield takeEvery(GET_CONTENT_INTERACTIONS_STATE, getContentsInteractionsStates)
  yield takeEvery(GET_CONTENT_INTERACTIONS_ZIPCODES, getContentsInteractionsZipcodes)
  yield takeEvery(GET_CONTENT_INTERACTIONS, getContentInteractions)
  yield takeEvery(UPDATE_CONTENTS, updateContent)
  // yield takeEvery(ADD_CONTENTS, addContents)
  yield takeEvery(CHANGE_CONTENT_GRAYLIST_STATUS, changeContentGraylistStatus)
  yield takeEvery(GET_CONTENT_GRAYLIST, getContentGraylist)
}

export default ContentSaga
