import {
  CHANGE_GRAYLIST_STATUS,
  GET_USERS_GRAYLIST,
  SET_USERS_GRAYLIST,
  GET_GRAYLIST_ZIPCODE,
  SET_GRAYLIST_ZIPCODE,
} from "./actionTypes"

export const changeGraylistStatus = payload => ({
  type: CHANGE_GRAYLIST_STATUS,
  payload,
})

export const getUserGraylist = payload => ({
  type: GET_USERS_GRAYLIST,
  payload,
})
export const setUserGraylist = glist => ({
  type: SET_USERS_GRAYLIST,
  payload: glist,
})

export const getGraylistZipcode = () => ({ type: GET_GRAYLIST_ZIPCODE })
export const setGraylistZipcode = zcode => ({
  type: SET_GRAYLIST_ZIPCODE,
  payload: zcode,
})
