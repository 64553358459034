import { SET_MENU_HEADER } from "./actionTypes"

const INIT_STATE = {
  menuData: {
    data: null,
  },
}

const MenuHeader = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MENU_HEADER:
      return {
        ...state,
        menuData: {
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default MenuHeader
