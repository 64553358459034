import { call, put, takeEvery } from 'redux-saga/effects'
import cogoTost from 'cogo-toast'
import { get, put as putMethod, post } from 'helpers/api_helper'
import axios from 'axios'

import { CHANGE_GRAYLIST_STATUS, GET_USERS_GRAYLIST, GET_GRAYLIST_ZIPCODE } from 'store/graylist/actionTypes'

import { setUserGraylist, setGraylistZipcode } from './actions'

function* changeGraylistStatus({ payload: { status, users, update = false, getUsers, getUserGraylist, q, setIds, history, redir } }) {
  try {
    const { data } = yield call(post, `/admin/users/change-status`, {
      status,
      users
    })
    if (update) {
      if (getUsers) {
        yield put(getUsers({ q }))
      } else if (getUserGraylist) {
        yield put(getUserGraylist({ q }))
      }
      // setIds([])
      redir && history.push(redir)
      cogoTost.success(data.message)
    }
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getUserGraylist({ payload: { q } }) {
  try {
    yield put(setUserGraylist({ isLoading: true, data: null }))
    const { data } = yield call(get, `/admin/users/graylisted?${q}`, {})
    yield put(setUserGraylist({ data: data.data, isLoading: false }))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* getGraylistZipcode() {
  try {
    const { data } = yield call(get, '/admin/users/graylisted/zipcodes')
    const _data = data.data.map((d) => ({ ...d, label: d._id, value: d._id }))
    _data.unshift({ label: 'ALL', value: 'all' })
    yield put(setGraylistZipcode(_data))
  } catch (error) {
    cogoTost.error(error.response.data.message)
  }
}

function* GraylistSaga() {
  yield takeEvery(CHANGE_GRAYLIST_STATUS, changeGraylistStatus),
    yield takeEvery(GET_USERS_GRAYLIST, getUserGraylist),
    yield takeEvery(GET_GRAYLIST_ZIPCODE, getGraylistZipcode)
}

export default GraylistSaga
