import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

import User from "./user/reducer"
import Manager from "./manager/reducer"

//Calendar
import calendar from "./calendar/reducer"

import Content from "./contents/reducer"
import Help from "./support/reducer"
import MenuHeader from "./menu-header/reducer"
import Graylist from "./graylist/reducer"
import Dashboard from "./dashboard/reducer"

const rootReducer = combineReducers({
  Manager,
  Content,
  Help,
  MenuHeader,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  User,
  Graylist,
  Dashboard,
  calendar,
})

export default rootReducer
