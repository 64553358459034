import { SET_MANAGER, SET_MANAGERS, SET_MANAGER_INFO } from "./actionTypes"

const INIT_STATE = {
  manager: {
    id: null,
    createdAt: null,
  },
  managers: {
    isLoading: true,
    current: 0,
    pages: 0,
    total: 0,
    records: [],
    from: 0,
    to: 0,
  },
  info: {
    isLoading: true,
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
    roleName: "",
    status: 1,
    content: { viewOnly: false, allAccess: false },
    announcements: { viewOnly: false, allAccess: false },
    contentGraylist: { viewOnly: false, allAccess: false },
    customers: { viewOnly: false, allAccess: false },
    defaultContent: { viewOnly: false, allAccess: false },
    graylist: { viewOnly: false, allAccess: false },
    invoices: { viewOnly: false, allAccess: false },
    managers: { viewOnly: false, allAccess: false },
    overview: { viewOnly: false, allAccess: false },
    pros: { viewOnly: false, allAccess: false },
    tickets: { viewOnly: false, allAccess: false },
  },
}

const Manager = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MANAGER:
      return {
        ...state,
        manager: {
          ...action.payload,
        },
      }

    case SET_MANAGERS:
      return {
        ...state,
        managers: {
          ...state.managers,
          ...action.payload,
        },
      }

    case SET_MANAGER_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default Manager
