import {
  SET_CONTENT_STATES,
  SET_CONTENT_CITIES,
  SET_CONTENT_ZIPCODES,
  SET_CONTENTS,
  SET_CONTENTS_INFO,
  SET_CONTENT_INTERACTIONS_CITY,
  SET_CONTENT_INTERACTIONS_STATE,
  SET_CONTENT_INTERACTIONS_ZIPCODES,
  SET_CONTENT_INTERACTIONS,
  SET_CONTENT_GRAYLIST,
} from "./actionTypes"

const INIT_STATE = {
  states: [],
  cities: [],
  zipcodes: [],
  contents: {
    isLoading: false,
    current: 0,
    pages: 0,
    total: 0,
    records: [],
    from: 0,
    to: 0,
  },
  content: {
    isLoading: true,
    data: null,
  },
  interactionsCities: [],
  interactionsStates: [],
  interactionsZipcodes: [],
  interactionsContent: {
    isLoading: false,
    current: 0,
    pages: 0,
    total: 0,
    records: [],
    from: 0,
    to: 0,
  },

  contentGraylist: {
    isLoading: true,
    data: null,
  },
}

const Content = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONTENT_STATES:
      return { ...state, states: action.payload }

    case SET_CONTENT_CITIES:
      return { ...state, cities: action.payload }

    case SET_CONTENT_ZIPCODES:
      return { ...state, zipcodes: action.payload }

    case SET_CONTENTS:
      return {
        ...state,
        contents: {
          ...state.contents,
          ...action.payload,
        },
      }

    case SET_CONTENTS_INFO:
      return {
        ...state,
        content: {
          isLoading: action.payload.isLoading,
          data: action.payload.data,
        },
      }

    case SET_CONTENT_INTERACTIONS_CITY:
      return { ...state, interactionsCities: action.payload }

    case SET_CONTENT_INTERACTIONS_STATE:
      return { ...state, interactionsStates: action.payload }

    case SET_CONTENT_INTERACTIONS_ZIPCODES:
      return { ...state, interactionsZipcodes: action.payload }

    case SET_CONTENT_INTERACTIONS:
      return {
        ...state,
        interactionsContent: {
          ...state.interactionsContent,
          ...action.payload,
        },
      }

    case SET_CONTENT_GRAYLIST:
      return {
        ...state,
        contentGraylist: {
          ...state.contentGraylist,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default Content
