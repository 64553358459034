import PropTypes from 'prop-types'
import React, { Suspense, useEffect } from 'react'

import { Switch, BrowserRouter as Router } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes'

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware'

// layouts Format
import VerticalLayout from './components/VerticalLayout/'
import HorizontalLayout from './components/HorizontalLayout/'
import NonAuthLayout from './components/NonAuthLayout'

// Import scss
import './assets/scss/theme.scss'

import './app.css'
// ------- chart css --------
import SuspenseLoader from './components/SuspenseLoader/SuspenseLoader'
import moment from 'moment'

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
// fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// // init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = (props) => {
  const user = useSelector((state) => state.Profile.user)
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  useEffect(() => {
    // let interval = null
    // const _current = localStorage.getItem('session-active')
    // if (_current) {
    //   const t = moment(moment(_current)).diff(moment(), 'second')
    //   if (isNaN(t) || t <= 0) {
    //     localStorage.clear()
    //     window.location.reload()
    //   }
    // } else {
    //   if (user) {
    //     localStorage.setItem('session-active', moment().add(10, 'minute').format())
    //     interval = setInterval(() => {
    //       const current = localStorage.getItem('session-active')
    //       const t = moment(moment(current)).diff(moment(), 'second')
    //       if (isNaN(t) || t <= 0) {
    //         localStorage.clear()
    //         window.location.reload()
    //       }
    //     }, 1000)
    //     const sessionActive = localStorage.getItem('session-active')
    //     if (sessionActive) {
    //       const events = ['search', 'change', 'click', 'dblclick', 'drag', 'dragend', 'dragenter', 'dragleave', 'dragover', 'dragstart', 'drop', 'focus', 'input', 'keydown', 'keypress', 'keyup', 'mousedown', 'mouseenter', 'mouseleave', 'mousemove', 'mouseout', 'mouseover', 'mouseup', 'mousewheel', 'reset', 'resize', 'scroll', 'select', 'submit', 'toggle', 'volumechange', 'wheel', 'selectstart', 'selectionchange']
    //       events.forEach((key) => {
    //         window.addEventListener(key, (event) => {
    //           localStorage.setItem('session-active', moment().add(10, 'minute').format())
    //         })
    //       })
    //     }
    //   } else {
    //     if (interval) clearInterval(interval)
    //   }
    // }
  }, [user])

  // const restrictedRoutes = ["/pros", "/dashboard"]
  // const filteredRoutes = userRoutes.filter(item =>
  //   restrictedRoutes.every(restrict => item.path !== restrict)
  // )

  // const filteredRoutes = userRoutes.map(itm => {
  //   if (itm.path === "/pros") {
  //     return { ...itm, path: "/404" }
  //   }
  //   return itm
  // })

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Suspense fallback={<SuspenseLoader />}>
            {authRoutes.map((route, idx) => (
              <Authmiddleware path={route.path} layout={NonAuthLayout} component={route.component} key={idx} isAuthProtected={false} />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware path={route.path} layout={Layout} component={route.component} key={idx} isAuthProtected={true} exact />
            ))}
          </Suspense>
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout
  }
}

export default connect(mapStateToProps, null)(App)
